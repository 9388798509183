function Line(p) {
    var x;

    p.setup = function() {
    p.createCanvas(p.windowWidth, p.windowHeight);
    x = 0; // Start at the left edge of the canvas
    p.speed = 55
    }

    p.draw = function() {
    p.background(20, 20, 20);
    p.line(x, 0, p.windowWidth/4+x, p.windowHeight);
    p.stroke(143, 143, 143);
    p.strokeWeight(4);
    x = x + p.speed; // Move slightly to the right every frame by changing the value of x by 1
        if (x > p.width - p.windowWidth/9*4){
            p.speed *= 0;
        }
        if (x == 0){
            p.speed *= -1;
            }
        }

    p.windowResized = function() {
        p.resizeCanvas(p.windowWidth, p.windowHeight);
    }
}

export default Line;