import React from "react";
import Slide from "./Setup";

const Slider = () => {
    return (
        <>
            <Slide auto speed={3000}>
                <h3 className="title">
                Web Designer
                </h3>
                <h3 className="title">
                Web Developer
                </h3>
                <h3 className="title">
                UI/UX Designer
                </h3>
                <h3 className="title">
                Graphic Designer
                </h3>
                <h3 className="title">
                Creator
                </h3>
                <h3 className="title">
                Translator
                </h3>
            </Slide>
        </>
    )
}

export default Slider