import React from "react";
import "./App.css";
import { ReactP5Wrapper } from "react-p5-wrapper";
import Line from "./components/p5/Line";
import Slider from "./components/slider/Slider";
import Instagram from "./components/socials/Instagram";
import Twitter from "./components/socials/Twitter";
import Email from "./components/icons/Email";
import ArrowRight from "./components/icons/ArrowRight";
import Nakamura from "./img/nakamura.png";
import Niigaster from "./img/niigaster.png";
import HJK from "./img/HJK.png";
import Calm from "./img/calm.png";
import NDB from "./img/ndb.svg";

function App() {
  return (
      <div className="wrapper">
        <div className="shooting-star">
          <ReactP5Wrapper sketch={Line} />
        </div>
        <div className="bg"/>
        <div className="content">
          <h2 className="welcome">Welcome to <span>bungo.io</span></h2>
          <div className="profile-image"></div>
          <h2 className="name">Bungo Masuno</h2>
          <div className="slider">
            <Slider />
          </div>
          <div className="socials">
            <a href="https://instagram.com/bungomasuno" target="_blank" rel="noreferrer" className="instagram"><Instagram className={"instagram-icon"} width={18} height={18} fill={"#fff"} /></a>
            <a href="https://twitter.com/bungomasuno" target="_blank" rel="noreferrer" className="twitter"><Twitter className={"twitter-icon"} width={18} height={18} fill={"#fff"} /></a>
            <a href="mailto:hi@bungo.io" target="_blank" rel="noreferrer" className="email"><Email className={"email-icon"} width={18} height={18} fill={"#fff"} /></a>
          </div>
          <div className="portfolio">
            <a href="https://nakamurasetubi.co.jp/" target="_blank" rel="noreferrer" className="p-link">
              <div className="p-img">
                <img src={Nakamura} />
              </div>
              <p className="p-name">中村設備工業</p>
              <ArrowRight className={"arrow-right"} width={14} height={14} fill={"#fff"} />
            </a>
            <a href="https://niigaster.com/" target="_blank" rel="noreferrer" className="p-link">
              <div className="p-img">
                <img src={Niigaster} />
              </div>
              <p className="p-name">Niigaster</p>
              <ArrowRight className={"arrow-right"} width={14} height={14} fill={"#fff"} />
            </a>
            <a href="https://harajukupunks.com/" target="_blank" rel="noreferrer" className="p-link">
              <div className="p-img">
                <img src={HJK} />
              </div>
              <p className="p-name">Harajuku Punks</p>
              <ArrowRight className={"arrow-right"} width={14} height={14} fill={"#fff"} />
            </a>
            <a href="https://salon-calm.netlify.app/" target="_blank" rel="noreferrer" className="p-link">
              <div className="p-img">
                <img src={Calm} />
              </div>
              <p className="p-name">Salon Calm</p>
              <ArrowRight className={"arrow-right"} width={14} height={14} fill={"#fff"} />
            </a>
            <a href="https://newsdelibakery.nakamurasetubi.co.jp/" target="_blank" rel="noreferrer" className="p-link">
              <div className="p-img">
                <img src={NDB} />
              </div>
              <p className="p-name">newsDELI Bakery</p>
              <ArrowRight className={"arrow-right"} width={14} height={14} fill={"#fff"} />
            </a>
          </div>
        </div>
      </div>
  );
}

export default App;
